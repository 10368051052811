<template>
    <v-modal :is-open="isOpen"
        class="bg-white"
        @close="close">
        <v-modal-header @close="close">
            {{ t('picker-title') }}
        </v-modal-header>

        <div class="flex flex-col">
            <v-header-shop-picker-info v-if="activeOutlet"
                v-model="activeOutlet"
                :outlet="activeOutlet"
                :has-selection="!!selectedOutlet"
                class="mb-md"
                @close="close" />

            <v-header-shop-picker-item v-for="outlet in filterOutlets"
                :key="outlet.name"
                v-model="selectedOutlet"
                :outlet="outlet" />
        </div>
    </v-modal>
</template>

<script lang="ts" setup>
import type { ISbStoryData } from '@storyblok/js';
import {
    useGeolocation,
    useLocalStorage,
    useVModel,
    whenever,
} from '@vueuse/core';

const properties = defineProps<{
    modelValue?: ISbStoryData
    isOpen: boolean
    outlets?: ISbStoryData[]
}>();

const emit = defineEmits<{
    (event: 'close'): void
    (event: 'update:modelValue', value: ISbStoryData): void
}>();

const selectedOutlet = useVModel(properties, 'modelValue', emit);

const filterOutlets = computed(() => properties.outlets?.filter((outlet) => !/varasto/i.test(outlet.name)));

const { t } = useI18n();

const { coords, resume } = useGeolocation({
    immediate: false,
});

const location = useLocalStorage<{
    latitude?: number
    longitude?: number
}>('location', {});

const outlets = computed(() => {
    const items = properties.outlets || [];

    if (location.value.latitude && location.value.longitude) {
        items.sort((a, b) => {
            const coordsA = a.content.location.split(',').map(Number);
            const coordsB = b.content.location.split(',').map(Number);

            const aDistance = calculateDistance(
                coordsA[0],
                coordsA[1],
                location.value.latitude,
                location.value.longitude,
            );

            const bDistance = calculateDistance(
                coordsB[0],
                coordsB[1],
                location.value.latitude,
                location.value.longitude,
            );

            return aDistance - bDistance;
        });
    }

    return items;
});

const isLoading = ref(false);

const activeOutlet = computed<ISbStoryData | undefined>(() => {
    if (selectedOutlet.value) {
        isLoading.value = false;
        return outlets.value.find((outlet) => outlet.uuid === selectedOutlet.value?.uuid) ?? undefined;
    }

    if (location.value.latitude && location.value.longitude) {
        return outlets.value[0];
    }

    return undefined;
});

whenever(() => properties.isOpen, () => {
    if (!location.value.latitude || !location.value.longitude) {
        resume();
    }
});

whenever(coords, (value) => {
    if (value.latitude !== Number.POSITIVE_INFINITY && value.longitude !== Number.POSITIVE_INFINITY) {
        location.value = {
            latitude: value.latitude,
            longitude: value.longitude,
        };
    }
}, {
    immediate: true,
});

function close() {
    emit('close');
}
</script>

<i18n lang="json">
{
    "fi": {
        "picker-title": "Myymälät"
    },
    "et": {
        "picker-title": "Kauplused"
    }
}
</i18n>
